import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  LessonAnalytics_image,
  LessonAnalytics_massege_image,
  student_warning,
} from "../../../../../../assets/images/images";

import AOS from "aos";
import "aos/dist/aos.css";
import { useState } from "react";
import * as XLSX from "xlsx";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import {
  convertSecondsToMinutes,
  timeDifferenceInMinutes,
  formatTime,
  addFormattedTime,
} from "../../../../../../utils/utlis";
import QuestionNew from "../../../questions/Question_new";
import ShowTextPopup from "../course/ShowTextPopup";
import Helper from "../../../../../../utils/Helper";
import BackButton from "../../../../../../components/BackButton/BackButton";
import PreprocessAndRenderLatex from "../../../../../../components/ProcessRenderLatex";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";

const AssignmentAnalytics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { course_id, assignment_id, analytic_id } = useParams();
  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility
  const toggleDropdown = (index, array) => {
    setShowDropdown(!showDropdown); // Toggle the visibility state
    setAssignmentIndex(index);
    setArrayIndex(array);
  };

  const student_id = location.state?.student?.student_id ?? analytic_id;
  const assignmentId = location.state?.student?.assignment_id ?? assignment_id;
  const selectedAssignment = location.state?.selectedAssignment;

  const { token } = useAuth();
  const { role } = useSelector((state) => state.globalState);
  const [analyticHeadData, setAnalyticHeadData] = useState([]);
  const [maxTimeTakenMinutes, setmaxTimeTakenMinutes] = useState(0);
  const [maxTimeTakenSeconds, setmaxTimeTakenSeconds] = useState(0);
  const [
    assignmentcompletedDuration,
    setAssignmentcompletedDuration,
  ] = useState("");
  const [totalTimeTaken, settotalTimeTaken] = useState("");
  const [analyticDetailsData, setAnalyticDetailsData] = useState({});
  const [headerData, setheaderData] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [errorData, setErrorData] = useState({
    error: false,
    errormsg: "",
  });

  const getAssignmentAnalytics = async () => {
    setisLoading(true);
    api
      .get(
        `/assignments/submissions?student_id=${student_id}&assignment_id=${assignmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setisLoading(false);
          if (response.data?.data) {
            setAnalyticDetailsData(response.data?.data);
            setAnalyticHeadData(response.data?.data?.assignment_analytics);
            const completedAssignment = response.data?.data?.assignment_analytics.find(
              (item) => item.status === "Completed"
            );
            if (completedAssignment) {
              setheaderData(completedAssignment);
            } else {
              console.log("No completed assignments found.");
              // Optionally handle the case where there are no completed assignments
            }
            if (
              response.data?.data?.student_answers_json &&
              response.data?.data?.student_answers_json.length > 0
            ) {
              const timeTakenArray = response.data?.data?.ai_evaluation_json?.questions.map(
                (question) => question.time_taken
              );

              setAssignmentcompletedDuration(
                timeTakenArray[timeTakenArray.length - 1]
              ); // Get the last element
            }
            if (
              response.data?.data?.student_answers_json &&
              response.data?.data?.student_answers_json.length > 0
            ) {
              const timeTakenArray = response.data?.data?.ai_evaluation_json?.questions.reduce(
                (sum, item) => {
                  // Add either the time taken or 1 if the time taken is 0
                  return (
                    sum +
                    (item.time_taken === "0" ||
                    item.time_taken === 0 ||
                    !item.time_taken
                      ? 1
                      : parseInt(item.time_taken, 10))
                  );
                },
                0
              );
              // const timeTakenArray = response.data?.data?.student_answers_json.reduce((sum, item) => sum + item.time_taken, 0);
              // console.log("timeTakenArray", timeTakenArray);
              settotalTimeTaken(timeTakenArray); // Get the last element
            }
            const timeTakenValues = analyticDetailsData?.ai_evaluation_json?.questions.map(
              (question) => question.time_taken
            );
            const maxTimeTaken = Math.max(...timeTakenValues);
            // console.log('maxTimeTaken', maxTimeTaken % 60);
            setmaxTimeTakenMinutes(
              maxTimeTaken > 0 ? Math.floor(maxTimeTaken / 60) : 0
            );
            setmaxTimeTakenSeconds(maxTimeTaken > 0 ? maxTimeTaken % 60 : 0);
          } else {
            setErrorData({
              error: true,
              errormsg: response.data?.message ?? "No Record Found",
            });
          }
        } else {
          setErrorData({
            error: true,
            errormsg: response.data?.message ?? "Something went wrong",
          });
        }
      })
      .catch((error) => {
        setisLoading(false);
        setErrorData({
          error: true,
          errormsg: error,
        });
      });
  };
  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    localStorage.setItem("assignmentanalyticsdetails", JSON.stringify(selectedAssignment));
    getAssignmentAnalytics();
    if (analyticDetailsData.length > 0) {
      getRubric(analyticDetailsData?.rubric);
    }
    AOS.init({
      duration: 500,
    });
  }, [student_id, assignmentId]);

  //
  const [rubricData, setRubric] = useState(null);
  const [showRubric, setShowRubric] = useState(false);
  const toogle = (question) => {
    setShowRubric((p) => !p);
  };

  const getRubric = async (rubric_id) => {
    await api
      .get(`assignments/get-rubric/${rubric_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setRubric(response?.data?.data);
        } else {
          setRubric({
            rubric: null,
            lesson: null,
          });
        }
      })
      .catch((error) => {
        setRubric({
          rubric: null,
          lesson: null,
        });
      });
  };

  const [showdownlodefile, setShowdownlodefile] = useState(false); // State to control visibility
  const [fileNameError, setFileNameError] = useState("");
  const [fileNameDownload, setFileNameDownload] = useState("");
  const toggledownlodefile = () => {
    setFileNameDownload("");
    setFileNameError("");
    setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
  };
  const handleFileNameChange = useCallback((e) => {
    const enteredValue = e.target.value.trim();
    setFileNameDownload(e.target.value);
    if (!enteredValue) {
      setFileNameError("Please enter filename");
    } else {
      setFileNameError("");
    }
  }, []);

  const inputRefDownload = useRef(null);
  useEffect(() => {
    // console.log("Effect to focus input running", showdownlodefile, inputRefDownload.current);
    if (inputRefDownload.current) {
      inputRefDownload.current.focus();
    }
  }, [fileNameDownload]);

  const AssignmentHeader = () => {
    const exportToExcel = () => {
      if (fileNameDownload === null || fileNameDownload === "") {
        setFileNameError("Please enter filename");
        return; // Ensure function exits here if there's no file name
      }

      const wb = XLSX.utils.book_new();

      // Ensure data is available before proceeding
      if (analyticHeadData.length === 0 || !location.state) {
        console.error("Data is missing, cannot generate Excel file.");
        return;
      }

      const headerInfo = {
        // Assuming analyticHeadData and location.state have the necessary data
        "Assignment Name": analyticHeadData[0]?.assignment_name || "",
        "Student Name": analyticHeadData[0]?.student_name || "",
        "Assignment Score": location.state?.score || "",
        "Assignment Started At":
          Helper.formatDateTime(location.state.started_date).time +
          " " +
          Helper.formatDateTime(location.state.started_date).date,
        "Assignment Ended At":
          Helper.formatDateTime(location.state.completed_date).time +
          " " +
          Helper.formatDateTime(location.state.completed_date).date,
        "Time Duration":
          timeDifferenceInMinutes(
            analyticHeadData[0]?.completed_date,
            analyticHeadData[0]?.started_date
          ) || "",
      };

      const headerRows = [
        ["Assignment Name", headerInfo["Assignment Name"]],
        ["Student Name", headerInfo["Student Name"]],
        ["Assignment Score", headerInfo["Assignment Score"]],
        ["Assignment Started At", headerInfo["Assignment Started At"]],
        ["Assignment Ended At", headerInfo["Assignment Ended At"]],
        ["Time Duration", headerInfo["Time Duration"]],
      ];

      const wsHeader = XLSX.utils.aoa_to_sheet(headerRows);

      // Check if analyticDetailsData is available and in the correct format
      if (
        !analyticDetailsData?.student_answers_json ||
        !Array.isArray(analyticDetailsData.student_answers_json)
      ) {
        console.error("Invalid or missing student_answers_json data.");
        return;
      }

      const transformedData = analyticDetailsData?.ai_evaluation_json?.questions.map(
        (item, index, array) => ({
          "Sr No": index + 1,
          Question: analyticDetailsData?.student_answers_json[index]?.question,
          "Submitted Answer": item.student_answer,
          "Correct Answer": item.ai_answer,
          "Time Taken":
            index > 0
              ? convertSecondsToMinutes(
                  array[index - 1]
                    ? item.time_taken - array[index - 1].time_taken
                    : ""
                )
              : convertSecondsToMinutes(item.time_taken),
          "Answer Status": item?.evaluation,
        })
      );

      XLSX.utils.sheet_add_json(wsHeader, transformedData, { origin: "A8" });

      // const merge = [
      //   { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } }, // Merging first row cells
      //   { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // Merging second row cells
      //   // Add more merges as needed
      // ];

      // wsHeader["!merges"] = merge;

      XLSX.utils.book_append_sheet(wb, wsHeader, "AssignmentAnalytics");

      try {
        XLSX.writeFile(wb, fileNameDownload + ".xlsx");
      } catch (error) {
        console.error("Error writing Excel file:", error);
      }
    };

    const onStudentViewProfile = () => {
      // navigate(`/student/${location.state?.student_id}`);
      const student_id = location.state?.student_id || location.state?.student_id == undefined ? location.state?.student?.student_id : location.state?.student_id;
      navigate(`/student/${student_id}`);
    };

    return (
      <>
        <div className="view-course-Details-back-btn-folder">
          <BackButton buttonName={"Assignment Analytics"} />
          {/* <i
            onClick={() => navigate(-1)}
            className="fa-solid fa-arrow-left"
          ></i>
          <h2>Assignment Analytics</h2> */}
          {/* <span style={{ fontWeight: "600", width: "100%", textAlign: "center" }}>Assignment Analytics</span> */}
        </div>

        {/* <div className="Analytics-profile-container">
          <p>
            {analyticHeadData.length > 0 &&
              analyticHeadData[0]?.assignment_name}
          </p>
          <div
            className="LessonViewProgress-tab-filter-downlode-folder"
            onClick={toggledownlodefile}
          >
            <span>
              <i className="fa-solid fa-download"></i>
            </span>
          </div>
        </div>
        <div className="LessonAnalytics-profile-container">
          <div className="LessonAnalytics-profile">
            <div className="LessonAnalytics-profile-image">
              <img
                src={
                  analyticHeadData.length > 0 &&
                  (analyticHeadData[0]?.student_profile_pic ||
                    LessonAnalytics_image)
                }
                alt=""
              />
            </div>
            <h2>
              {analyticHeadData.length > 0 && analyticHeadData[0]?.student_name}
            </h2>
            {role === "Teacher" && <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                onStudentViewProfile();
              }}
            >
              View Profile
            </a>}
          </div>
          <div className="LessonAnalytics-profile-folder">
            <p>Assignment Score</p>
            <h2> */}
        {/* {location.state?.score !== null && location.state?.score !== ""
                ? location.state?.score
                : "- -"} */}
        {/* {analyticDetailsData.marks_awarded} / {analyticDetailsData.total_marks}
            </h2>
          </div>
          <div className="LessonAnalytics-profile-folder">
            <p>Assignment Started At</p>
            <>
              <h2>
                {Helper.formatDateTime(analyticHeadData[0]?.started_date).time}
              </h2>
              <span className="lessonanalystics-date">
                {Helper.formatDateTime(analyticHeadData[0]?.started_date).date}
              </span>
            </>
          </div>

          {analyticHeadData.length > 0 && (
            <div className="LessonAnalytics-profile-folder">
              <p>Assignment Ended At</p>
              <>
                <h2>
                  {addFormattedTime(Helper.formatDateTime(analyticHeadData[0]?.started_date).time,formatTime(assignmentcompletedDuration))}
                </h2>
                <span className="lessonanalystics-date">
                  {Helper.formatDateTime(analyticHeadData[0]?.started_date).date}
                </span>
              </>
            </div>
          )} */}
        {/* <div className="LessonAnalytics-profile-folder">
            <p>Time Duration</p>
            <h2>{formatTime(assignmentcompletedDuration)} */}
        {/* {maxTimeTakenMinutes > 0 ?
                (maxTimeTakenMinutes+' : '+maxTimeTakenSeconds +' min') : maxTimeTakenSeconds+' sec'
                }
              {analyticHeadData.length > 0 &&
                timeDifferenceInMinutes(
                  location.state?.started_date,
                  location.state?.completed_date,
                  analyticHeadData[0]?.completed_date,
                  analyticHeadData[0]?.started_date
                )} */}
        {/* </h2>
          </div> */}

        {/* <div className="LessonAnalytics-profile-massege-image">
            <img src={LessonAnalytics_massege_image} alt="" />
          </div> */}
        {/* </div> */}

        {/* AssignmentAnalytics date board start */}
        {/* Hidden For Live */}
        <div className="LessonAnalytics-profile-container">
          <div className="LessonAnalytics-profile">
            <div className="LessonAnalytics-profile-image">
              <img
                src={
                  analyticHeadData.length > 0 &&
                  (analyticHeadData[0]?.student_profile_pic ||
                    LessonAnalytics_image)
                }
                alt=""
              />
            </div>
            <h2>
              {analyticHeadData.length > 0 && analyticHeadData[0]?.student_name}
            </h2>
            {role === "Teacher" && (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // This prevents the default anchor link behavior
                  onStudentViewProfile();
                }}
              >
                View Profile
              </a>
            )}
          </div>
          <div className="LessonAnalytics-profile-folder">
            <p>Score</p>
            <h2>
              {analyticDetailsData.marks_awarded} /{" "}
              {analyticDetailsData.total_marks}
            </h2>
          </div>
          <div className="LessonAnalytics-profile-folder">
            <p>Assignment</p>
            <p>Created Date</p>
            <>
              <h2>{Helper.formatDateTime(headerData?.created_at).date}</h2>
            </>
          </div>
          {headerData?.assignment_end_date && (
            <div className="LessonAnalytics-profile-folder">
              <p>Assignment</p>
              <p>End Date</p>
              <>
                <h2>
                  {Helper.formatDateTime(headerData?.assignment_end_date).date}
                </h2>
              </>
            </div>
          )}

          {analyticHeadData.length > 0 && (
            <div className="LessonAnalytics-profile-folder">
              <p>Student</p>
              <p>Started On</p>
              <>
                <h2>{Helper.formatDateTime(headerData?.started_date).date}</h2>
                <span className="lessonanalystics-date">
                  {Helper.formatDateTime(headerData?.started_date).time}
                </span>
              </>
            </div>
          )}
          {analyticHeadData.length > 0 && (
            <div className="LessonAnalytics-profile-folder">
              <p>Student</p>
              <p>Submitted On</p>
              <>
                <h2>
                  {Helper.formatDateTime(headerData?.completed_date).date}
                </h2>
                <span className="lessonanalystics-date">
                  {Helper.formatDateTime(headerData?.completed_date).time}
                </span>
              </>
            </div>
          )}
          <div className="LessonAnalytics-profile-folder">
            <p>Time Allotted</p>
            <h2>{formatTime(headerData?.assignment_duration * 60)}</h2>
          </div>
          <div className="LessonAnalytics-profile-folder">
            <p>Time Taken</p>
            <h2
              style={{
                color:
                  headerData?.assignment_duration * 60 < totalTimeTaken
                    ? "red"
                    : "",
              }}
            >
              {formatTime(totalTimeTaken)}
            </h2>
            <span
              style={{
                color:
                  headerData?.assignment_duration * 60 < totalTimeTaken
                    ? "red"
                    : "",
              }}
              className="lessonanalystics-date"
            >
              {" "}
              {headerData?.assignment_duration * 60 < totalTimeTaken
                ? "Delayed Submission"
                : ""}
            </span>
          </div>

          {/* <div className="LessonAnalytics-profile-massege-image">
            <img src={LessonAnalytics_massege_image} alt="" />
          </div> */}
        </div>
        {/* Hidden For Live */}
        {/* AssignmentAnalytics date board end */}

        {showdownlodefile && (
          <div className="Download-File-popup-wrappper">
            <div className="Download-File-popup-container">
              <div className="download-popup">
                <h2>Download File as</h2>
                <button className="close-btn" onClick={toggledownlodefile}>
                  ×
                </button>
                <div className="input-section">
                  <label>
                    File Name *{" "}
                    <input
                      ref={inputRefDownload}
                      type="text"
                      name="namefiletodnld"
                      placeholder="Enter File Name"
                      value={fileNameDownload}
                      onChange={handleFileNameChange}
                    />
                    {fileNameError && (
                      <p style={{ color: "red" }}>{fileNameError}</p>
                    )}
                  </label>
                </div>
                <div className="button-section">
                  {/* <button className="pdf-btn">PDF (.pdf)</button>
                  <span>Or</span> */}
                  <button className="spreadsheet-btn" onClick={exportToExcel}>
                    Spreadsheet (.xlsx)
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [arrayIndex, setArrayIndex] = useState(0);
  return (
    <>
      <div id="LessonAnalytics-section">
        <div className="LessonAnalytics-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <AssignmentHeader />
                <div className="LessonViewProgress-table-container AssignmentAnalytics-table-container">
                  <table>
                    <thead>
                      <tr>
                        <th style={{ width: "5%", textAlign: "center" }}>
                          Sr no.
                        </th>
                        <th>Question</th>
                        <th style={{ textAlign: "center" }}>
                          Submitted Answer
                        </th>
                        {/* <th style={{ textAlign: "left" }}>Correct Answer</th> */}
                        <th>Time Taken</th>
                        <th>Answer Status</th>
                        <th></th>
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div style={{ display: "table-caption" }}>
                        <Grid
                          height={"50vh"}
                          xs={12}
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <CircularProgress size={80} />
                        </Grid>
                      </div>
                    ) : analyticDetailsData?.student_answers_json?.length >
                      0 ? (
                      <tbody>
                        {analyticDetailsData?.student_answers_json?.map(
                          (question, index, array) => (
                            <tr key={index}>
                              <td style={{ width: "5%", textAlign: "center" }}>
                                {index + 1}
                              </td>
                              <td style={{ width: "30%" }}>
                                <span style={{ display: "flex" }}>
                                  <PreprocessAndRenderLatex
                                    content={question.question}
                                    wrapperStyle={{
                                      width: "250px",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  />
                                </span>
                              </td>
                              <td style={{ width: "15%", textAlign: "center" }}>
                                <span style={{ width: "93%", display: "flex" }}>
                                  <ul className="Submitted-Answer-list">
                                    <li>
                                      <PreprocessAndRenderLatex
                                        content={question.student_answer}
                                        wrapperStyle={{
                                          width: "250px",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      />
                                    </li>
                                  </ul>
                                </span>
                              </td>
                              {/* <td style={{ width: "13%", textAlign: "left" }}>
                              <ul className="CorrectAnswerr-list">
                                <li
                                  // onClick={toogle}
                                  //apply style
                                  style={{
                                    cursor: "pointer",
                                    color: "#2A3158",
                                  }}
                                >
                                  <PreprocessAndRenderLatex
                                    content={question.ai_answer}
                                  />
                                </li>
                              </ul>
                            </td> */}
                              <td style={{ width: "15%" }}>
                                <div className="hover-wrapper">
                                  <div className="completedat-time">
                                    {/* Hidden For Live */}
                                    {convertSecondsToMinutes(
                                      analyticDetailsData?.ai_evaluation_json
                                        ?.questions[index].time_taken
                                    )}
                                    {/* Hidden For Live */}
                                    {/* {array[index + 1]?.time_taken - question?.time_taken } */}
                                    {/* {convertSecondsToMinutes(question.time_taken)} */}
                                    {/* Old Timer Calculation */}
                                    {/* {index > 0 ? convertSecondsToMinutes(array[index - 1] ? question.time_taken - array[index - 1].time_taken : '') : convertSecondsToMinutes(question.time_taken)} */}
                                    {/* {question.time_taken &&
                                    Math.floor(
                                      (question.time_taken ?? 0) / 60
                                    ) > 5 && (
                                      <img src={student_warning} alt="" />
                                    )}
                                    Old Timer Calculation */}
                                  </div>
                                  {/* <div className="completed-lesson-inner-popup stdent-question-and-answer-popup">
                                  <p>
                                    Student took more time than average to
                                    answer this question
                                  </p>
                                </div> */}
                                </div>
                              </td>
                              <td style={{ width: "15%" }}>
                                <div
                                  className={`LessonViewProgress-student-status AssignmentAnalytics-answer-status ${
                                    analyticDetailsData?.ai_evaluation_json
                                      ?.questions[index]?.evaluation ===
                                    "Correct"
                                      ? "active"
                                      : analyticDetailsData?.ai_evaluation_json
                                          ?.questions[index]?.evaluation ===
                                        "Incorrect"
                                      ? "inactive"
                                      : "partial"
                                  }`}
                                >
                                  <span>
                                    {
                                      analyticDetailsData?.ai_evaluation_json
                                        ?.questions[index]?.evaluation
                                    }
                                    <i
                                      className={`fa-solid ${
                                        analyticDetailsData?.ai_evaluation_json
                                          ?.questions[index]?.evaluation ===
                                        "Correct"
                                          ? "fa-check"
                                          : analyticDetailsData
                                              ?.ai_evaluation_json?.questions[
                                              index
                                            ]?.evaluation === "Incorrect"
                                          ? "fa-x"
                                          : "fa-check"
                                      }`}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td style={{ width: "15%" }}>
                                <a
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    toggleDropdown(index, array);
                                  }}
                                >
                                  View Assessment
                                </a>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    ) : (
                      <div style={{ display: "table-caption" }}>
                        <p style={{ textAlign: "center", margin: "20px auto" }}>
                          No Assignment Analytics Found
                        </p>
                      </div>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {showRubric && (
            <ShowTextPopup
              title="Rubric"
              data={rubricData?.rubric ?? "No Rubric Found"}
              onClick={toogle}
            />
          )}
          {showDropdown && (
            <div className="AssignmentViewProgress-mock-test-wrapper">
              <div
                className="AssignmentViewProgress-mock-test-container"
                data-aos="zoom-in"
              >
                {
                  <QuestionNew
                    assignment={
                      analyticDetailsData?.student_answers_json[assignmentIndex]
                    }
                    ai_evaluation_json={analyticDetailsData?.ai_evaluation_json}
                    index={assignmentIndex}
                    arrayIndex={arrayIndex}
                    currentStep={assignmentIndex + 1}
                    totalSteps={
                      analyticDetailsData?.student_answers_json.length
                    }
                    handleNext={() =>
                      assignmentIndex <
                      analyticDetailsData?.student_answers_json.length - 1
                        ? setAssignmentIndex((p) => p + 1)
                        : null
                    }
                    handleBack={() => setAssignmentIndex((p) => p - 1)}
                    isLast={
                      assignmentIndex ===
                      analyticDetailsData?.student_answers_json.length - 1
                    }
                    isFirst={assignmentIndex === 0}
                    close={
                      <span
                        onClick={toggleDropdown}
                        className="AssignmentViewProgress-mock-test-close-btn"
                      >
                        <i className="fa-solid fa-x"></i>
                      </span>
                    }
                    questions={
                      analyticDetailsData?.ai_evaluation_json?.questions
                    }
                  />
                }

                {/* <div className="AssignmentViewProgress-mock-test-status-container">
                  <div className="AssignmentViewProgress-mock-test-status-folder">
                    <p>Answer Status</p>
                    <div className="AssignmentViewProgress-mock-test-status-correction">
                      <p>Correct</p>
                      <span>
                        <i className="fa-solid fa-check"></i>
                      </span>
                    </div>
                  </div>
                  <div className="AssignmentViewProgress-mock-test-status-folder">
                    <p>Time Taken</p>
                    <div className="AssignmentViewProgress-mock-test-status-correction AssignmentViewProgress-mock-test-status-time-duration">
                      <p>1 min 00 s</p>
                      <img src={student_warning} alt="" />
                    </div>
                  </div>
                  <span
                    onClick={toggleDropdown}
                    className="AssignmentViewProgress-mock-test-close-btn"
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                </div>
                <div className="AssignmentViewProgress-mock-test-question-answer-container">
                  <h2>
                    Question <span>1</span>: What is neuroscience?
                  </h2>
                  <div className="AssignmentViewProgress-mock-test-answer-container">
                   
                    <div className="options AssignmentViewProgress-mock-test-answer-container">
                      {questions[0].options.map((option, index) => (
                        <div
                          className={`option AssignmentViewProgress-mock-test-answer-folder ${
                            index === questions[0].correct ? "correct" : ""
                          }`}
                          key={index}
                        >
                          {String.fromCharCode(65 + index)}) {option}
                        </div>
                      ))}
                      <AssignmentDesFeild />
                    </div>
                  </div>
                  <div className="pagination">
                    <span>❮ 1 / 5 ❯</span>
                  </div>
                </div>  */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AssignmentAnalytics;
