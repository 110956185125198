import React, { useState, useEffect } from "react";
import { ProschoolLogo } from "../../../assets/images/images";
import {NavLink, NavLink as RouterNavLink, useNavigate } from "react-router-dom"; // Renamed to RouterNavLink
import { image1 } from "../../../assets/images/images";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from 'react-scroll';
import { useSelector } from "react-redux";
import CountdownTimer from "../../../components/CommingSoon/CommingSoon";

function HomeNavbar() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between("xs", "767"));
  const [opentogglebtn, setopentogglebtn] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const toggleBtn = () => {
    setopentogglebtn(!opentogglebtn);
  };

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setopentogglebtn(false);
    // Navigate to the home page
    navigate("/");
  
    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById('revolution_sec_new');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Adjust the timeout as needed
  };
  
  const handleContactClick = (e) => {
    e.preventDefault(); // Prevent default link behavior
    setopentogglebtn(false);
  };

  const startDate = 'April 14, 2024 00:00:00';
  const endDate = 'April 15, 2024 14:00:00';

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <nav className={`header_main_sec ${isScrolled ? 'scrolled' : ''}`}>
        <CountdownTimer startDate={startDate} endDate={endDate} />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="header_main_sec_inner">
                <div className="header-mob-folder">
                  <div className="header_logo" onClick={() => navigate(`/`)}>
                    <img src={subadmin_details ? subadmin_details?.school_image : ProschoolLogo} alt="logo" />
                  </div>
                  {isSmallScreen ? (
                    <div className="nevbar-toggle-btn" onClick={toggleBtn}>
                      <a>
                        <i class="fa-solid fa-bars-staggered"></i>
                      </a>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="header_menu">
                  <ul>
                    <li>
                      <RouterNavLink to="/">Home</RouterNavLink>
                    </li>
                    <li>
                      <RouterNavLink to="/about">About Us</RouterNavLink>
                    </li>
                    <li>
                      <a href="revolution_sec_new" onClick={handleClick}>
                        Features
                      </a>
                    </li>
                    <li>
                      <Link to="footer-section">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div className="header_login">
                {/* <a href="https://crm.zoho.in/bookings/ProSchoolDemoLink?rid=9424d97aec6e11d1c3ae7d9c6646b7c7c3e9aa55254364fc19a39b4e58b119faf0d28843c0b135f8f3fd6c3c4b0b201cgid9bc6a2232969d709e2e525070d49ce0aef91c1940bc27de028796ab325e88d0c" className="header-book-demo">Book a Demo</a> */}
                  {/* <a href="https://crm.zoho.in/bookings/ProSchoolDemo?rid=6178c3ebae0db6dde263550288f2e43dcb116db82b90fe56443a8e99d785770849f0b633869eb9b32b0d21a8650121b5gid8448aaaed34574ac158ffd90cf68d04d4dbf42fd89ec0c98e2bfd39b89e7e432" className="header-book-demo">Book a Demo</a> */}
                  {/* <a href="https://crm.zoho.in/bookings/ProSchoolDemo?rid=4c3acf078d1fc29f4c1f7fd70c576d8f19505cd3e84cfd24cf125f8257debbad8e35bf8874f8da86a31dd096519d227egid9281316b1e8cbaaae0aed500f846fa5efecdddc030b98eb4c0bfe7a474ec4a1f" className="header-book-demo">Book a Demo</a> */}
                  <a href="https://calendly.com/d/crkg-4rz-8yh/proschool-demo" className="header-book-demo">Book a Demo</a>
                  {/* <NavLink to="/login" className="login_btn">
                    Login
                  </NavLink> */}
                  <NavLink to="/login" className="sign_btn">
                   Login / Sign Up
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {opentogglebtn && (
        <nav className="header_main_sec" id="responsive-main-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="header_main_sec_inner" id="responsive-header">
                  <div className="header_logo">
                    <img src={ProschoolLogo} alt="logo" />
                  </div>
                  <div className="header_menu-container">
                  <div className="header_menu">
                      <ul>
                        <li>
                          <RouterNavLink to="/">Home</RouterNavLink>
                        </li>
                        <li>
                          <RouterNavLink to="/about">About Us</RouterNavLink>
                        </li>
                        <li>
                          <a href="revolution_sec_new" onClick={handleClick}>Features</a>
                        </li>
                        <li>
                          <Link to="footer-section" onClick={handleContactClick}>Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="header_login">
                      <NavLink to="/login" className="login_btn">
                        Login
                      </NavLink>
                      <NavLink to="/signup" className="sign_btn">
                        Sign Up
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-toggle-btn" onClick={toggleBtn}>
            <a>
              <i class="fa-solid fa-x"></i>
            </a>
          </div>
        </nav>
      )}
    </div>
  );
}

export default HomeNavbar;