import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PreprocessAndRenderLatex from "../../../../components/ProcessRenderLatex";
import StudentLessonChatbot from "../../../../components/studentLessonChatbot/StudentLessonChatbot";
import { useAuth } from "../../../../features/auth/AuthContext";
import DrawingCanvas from "../../../DrawingCanvas";
import AnswerInput from "./AnswerInput";
import MCQs from "./MCQs";

function Question({
  duration,
  assignment,
  index,
  currentStep,
  handleNext,
  handleBack,
  isLast,
  lesson,
  course_id,
  lesson_id,
  assignment_id,
  isTeacher,
  timers,
  setTimers,
  virtualAssistant,
}) {
  const { token } = useAuth();
  const [selectedOption, setSelectedOption] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [extractedText, setExtractedText] = useState(null);
  const [openWorkboard, setOpenWorkboard] = useState(false);
  const [convertedText, setConvertedText] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  // Update selectedOption whenever extractedText changes

  useEffect(() => {
    if (!isTeacher) {
      const sAPDtls_AssignmentId = localStorage.getItem(
        `sAPDtls_AssignmentId${assignment_id}${token}`
      );

      if (sAPDtls_AssignmentId && sAPDtls_AssignmentId == assignment_id) {
        const sAProgressDtls =
          JSON.parse(
            localStorage.getItem(
              `studentAssignmentProgressOnSelect${assignment_id}${token}`
            )
          ) || [];

        if (sAProgressDtls[currentStep]) {
          const currentAnswer = sAProgressDtls[currentStep].student_answer;

          if (!assignment.options || assignment.options.length === 0) {
            setSelectedOption(currentAnswer);
          } else {
            const matchingOptionIndex = assignment.options.findIndex(
              (option) => option === currentAnswer
            );
            setSelectedOption(
              matchingOptionIndex !== -1 ? matchingOptionIndex : null
            );
          }
        } else {
          setSelectedOption(null);
        }
      }
    }
  }, [assignment, currentStep, assignment_id, token]);

  useEffect(() => {
    if (extractedText) {
      setSelectedOption(extractedText);
      if (!isTeacher) {
        updateLocalStorage(currentStep, extractedText);
      }
    }
  }, [extractedText]);

  const updateLocalStorage = (step, answer) => {
    const sAProgressDtls =
      JSON.parse(
        localStorage.getItem(
          `studentAssignmentProgressOnSelect${assignment_id}${token}`
        )
      ) || [];

    while (sAProgressDtls.length <= step) {
      sAProgressDtls.push({});
    }

    sAProgressDtls[step] = { ...sAProgressDtls[step], student_answer: answer };
    localStorage.setItem(
      `studentAssignmentProgressOnSelect${assignment_id}${token}`,
      JSON.stringify(sAProgressDtls)
    );
  };

  const handleOptionChange = (type, value, index = null) => {
    if (type === "MCQ" || type === "TF") {
      setSelectedOption(index);
      const answer = assignment.options ? assignment.options[index] : index;
      if (!isTeacher) {
        updateLocalStorage(currentStep, answer);
      }
    } else {
      setSelectedOption(value);
      if (!isTeacher) {
        updateLocalStorage(currentStep, value);
      }
    }
  };

  const handleNextClick = () => {
    if (
      (selectedOption === -1 ||
        selectedOption === null ||
        selectedOption === undefined ||
        (typeof selectedOption === "string" && selectedOption.trim() === "")) &&
      !isTeacher
    ) {
      toast.error("Please select an option before proceeding.");
      return;
    } else {
      // if (!timers[index]) {
      //   setTimers((prevTimers) => {
      //     const newTimers = [...prevTimers];
      //     newTimers[index] = 0;
      //     return newTimers;
      //   });
      // }
      if (!isTeacher) {
        if (!timers && !timers[index]) {
          setTimers((prevTimers) => {
            const newTimers = prevTimers ? [...prevTimers] : [];
            newTimers[index] = 1;
            return newTimers;
          });
        }
      }

      const responseData = {
        ...assignment,
        time_taken: timers ? timers[index] : 1,
        student_answer:
          assignment.options?.length > 0
            ? assignment.options[selectedOption]
            : selectedOption,
      };

      handleNext(responseData);
    }
  };
  function switchQuestionView() {
    switch (assignment?.type) {
      case "FITB":
        return (
          <AnswerInput
            value={selectedOption || ""}
            onChange={(event) =>
              handleOptionChange(assignment?.type, event.target.value)
            }
            // onChange={(event) => {
            // const inputValue = event.target.value;
            // Update the state with whatever is currently in the input box
            // It will handle clearing the input entirely or adjusting based on user input.
            // setSelectedOption(inputValue);

            // }}
          />
        );

      case "MCQ":
      case "TF":
        return (
          <MCQs
            options={assignment.options}
            selectedOption={selectedOption}
            setSelectedOption={(index) =>
              handleOptionChange(assignment?.type, null, index)
            }
          />
        );
      default:
        return (
          <AnswerInput
            value={selectedOption || ""}
            // onChange={(event) => {
            //   const inputValue = event.target.value;
            //   // Update the state with whatever is currently in the input box
            //   // It will handle clearing the input entirely or adjusting based on user input.
            //   setSelectedOption(inputValue);
            //   setExtractedText(inputValue);
            // }}
            onChange={(event) => {
              handleOptionChange(assignment?.type, event.target.value);
              setExtractedText(event.target.value);
            }}
          />
        );
    }
  }

  const showWorkboard = () => setOpenWorkboard(true);
  const openPopup = () => setIsPopupVisible(true);
  const closePopup = () => setIsPopupVisible(false);

  const handleCanvaCancel = () => {
    setOpenWorkboard(false);
  };
  async function convertDrawingToText(imageData, setConvertedText) {
    try {
      const response = await fetch("https://api.mathpix.com/v3/text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          app_id: "Webarcitech_621a57", // Replace with your Mathpix app ID
          app_key: "7d36ed55c7649574c24f", // Replace with your Mathpix app key
        },
        body: JSON.stringify({
          src: imageData,
          formats: ["text"],
          math_inline_delimiters: ["$", "$"],
          rm_spaces: true,
        }),
      });
      const data = await response.json();
      // setConvertedText(data.text || "No text recognized");
      // if (data.latex && /\w+\s*\^|\_|\d+\s*\\frac/.test(data.latex)) {
      //   // This regex checks for superscripts, subscripts, fractions etc. typical in math
      //   setExtractedText(`$$` + data.latex + `$$`);
      //   console.log("`$$`+data.latex+`$$`", `$$` + data.latex + `$$`);
      //   console.log("extractedText", `$$` + data.latex + `$$`);
      // } else {
      //   // Remove LaTeX formatting if unnecessary
      //   const plainText = data.text.replace(/\\[()]/g, "");
      //   setExtractedText(plainText || "No text recognized");
      //   console.log("`$$`+data.latex+`$$`", `$$` + data.text + `$$`);
      //   console.log("extractedText", `$$` + data.text + `$$`);
      // }
      // setSelectedOption(data.text || "No text recognized");
      setExtractedText(data.text);
      setSelectedOption(data.text || "");
    } catch (error) {
      console.error("Error converting drawing:", error);
      setExtractedText("Error in conversion");
    }
  }

  function escapeHtmlEntities(text) {
    // return text.replace(/</g, "&lt;").replace(/>/g, "&gt;");
    return text
      ?.replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/_/g, "&#95;"); // Escape underscores
  }
  return currentStep === index ? (
    <>
      <div className="AssingmentMCQs-mock-test-question-answer-container">
        <h2>
          <span className="AssingmentMCQs-question">Q. {index + 1}:</span>
          <span className="AssingmentMCQs-answers">
            {
              <PreprocessAndRenderLatex
                content={escapeHtmlEntities(
                  assignment?.question?.replace(
                    /(\\\()(.*?)(\\\))/g,
                    (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`
                  )
                )}
              />
            }
          </span>
        </h2>
        {switchQuestionView()}

        {/* <div className="assignment-uplode-wrapper">
          {assignment?.type !== "FITB" &&
            assignment?.type !== "TF" &&
            assignment?.type !== "MCQ" && (
              <>
                <UplodeFile
                  onFileSelect={setImageUrl}
                  onTextExtract={setExtractedText}
                />

                <div className="UplodeFile-folder" onClick={showWorkboard}>
                  <i class="fa-solid fa-edit"></i>
                  <label>Workboard</label>
                </div>

                <div className="assignment-view-folder" onClick={openPopup}>
                  <div className="assignment-view-icon">
                    <img src={assignment_view_icon} />
                  </div>
                  <p>View</p>
                </div>
              </>
            )}
          {isPopupVisible && (
            <PreviewPopup value={extractedText} onClose={closePopup} />
          )}
        </div> */}

        <div className="AssingmentMCQs-mock-test-answer-btn-folder">
          {index !== 0 ? (
            <button onClick={handleBack}>
              <span className="AssingmentMCQs-mock-test-answer-left-arrow-btn">
                <i className="fa-solid fa-arrow-left"></i>
              </span>
              Back
            </button>
          ) : (
            // <button>
              <span className="AssingmentMCQs-mock-test-answer-left-arrow-btn">
                {/* <i className="fa-solid fa-arrow-left"></i> */}
              </span>
            // </button>
          )}
          {isLast ? (
            !isTeacher ? (
              <button onClick={handleNextClick}>
                Submit
                {/* {isLast && !isTeacher ? "Submit" : "Next"} */}
                <span className="AssingmentMCQs-mock-test-answer-right-arrow-btn">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            ) : (
              ""
            )
          ) : (
            <button onClick={handleNextClick}>
              Next
              {/* {isLast && !isTeacher ? "Submit" : "Next"} */}
              <span className="AssingmentMCQs-mock-test-answer-right-arrow-btn">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </button>
          )}
        </div>
      </div>

      {openWorkboard && (
        <DrawingCanvas
          onConvert={(dataUrl) =>
            convertDrawingToText(dataUrl, setConvertedText)
          }
          oncancel={handleCanvaCancel}
        />
      )}
      {/* {!isTeacher && lesson && ( */}
      {!isTeacher && lesson && virtualAssistant && (
        <>
          <StudentLessonChatbot
            ai_name={`${lesson?.teacher?.ai_name}`}
            teacher_id={lesson.teacher?.user_id}
            courseId={course_id}
            lesson_id={lesson_id}
            assignment_id={assignment_id}
            lesson_name={lesson.name ?? ""}
            ai_tone={lesson.teacher?.ai_tone}
            ai_avatar={lesson.teacher?.ai_avatar}
            ai_welcome_msg={lesson.teacher?.ai_welcome_msg}
            page_infojson={assignment}
          />{" "}
        </>
      )}
    </>
  ) : (
    <></>
  );
}

export default Question;
