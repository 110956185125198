import { useState, useEffect, useRef } from "react";
import { studentDashboard_Small_AI_chatbot_image } from "../../assets/images/images";
import { wsURL } from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import CustomChatbot from "../customChatbot/CustomChatbot";
import { Fab } from "@mui/material";
import {
  changeScrollPopup,
  isLinkOrText,
  useOutsideClick,
} from "../../utils/utlis";
import { getUserId } from "../../api/get_user_id";
import { createChatRoom } from "../../api/create_chat_room";
import { getChatHistory } from "../../api/get_chat_history";
import CustomTooltip from "../../pages/customtooltip/CustomTooltip";

function StudentLessonChatbot(props) {
  const { token } = useAuth();
  const {
    ai_welcome_msg,
    ai_avatar,
    ai_name,
    teacher_id,
    lesson_name,
    courseId,
    lessonId,
    assignment_id,
    page_infojson,
  } = props;
  const [openChatbot, setOpenChatbot] = useState(false);
  const [userId, setUserId] = useState();
  const [roomId, setRoomId] = useState();
  const [loading, setLoading] = useState(true);
  const [chatHistory, setChatHistory] = useState();
  const newWebSocketURL = `${wsURL}ws/course_chat/${roomId}/?token=${token}`;
  const payload = {
    student_user_id: userId,
    course_id: courseId,
    current_lesson: lesson_name,
    lesson_id: lessonId,
    assignment_id: assignment_id,
    page_infojson: page_infojson,
  };
  useEffect(() => {
    // if(openChatbot){
      getUserId(token).then((user_id) => {
        setChatHistory("");
        if (user_id !== null) {
          setUserId(user_id);
          createChatRoom({
            token: token,
            student_id: user_id,
            teacher_id: teacher_id,
          }).then((room_id) => {
            if (room_id !== null && room_id !== "") {
              setRoomId(room_id);
              if (userId !== null && teacher_id !== "") {
                getChatHistory({
                  token: token,
                  student_id: user_id,
                  teacher_id: teacher_id,
                }).then((history) => {
                  if (history !== null && history) {
                    setChatHistory(history);
                    setLoading(false);
                  }
                });
              }
            }
          });
        }
      });
    // }
    
  }, [openChatbot]);
  useEffect(() => {
    if (openChatbot === true) {
      changeScrollPopup("hidden", "studentDashboard-chatbot-container");
    } else {
      changeScrollPopup("auto", "studentDashboard-chatbot-container");
    }
  }, [openChatbot]);

  const chatbotRef = useRef(null); // Create a ref for the chatbot container

  useOutsideClick(chatbotRef, () => {
    setOpenChatbot(!openChatbot);
  });
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const closeTooltip = () => {
    setTooltipVisible(false);
  };
  return (
    <>
      <Fab
        sx={{
          position: "fixed",
          bottom: 45,
          right: 45,
          color: "common.white",
          "& img": {
            borderRadius: "100%",
          },
        }}
        size="small"
        aria-label="add"
      >
        <CustomTooltip
          title={ai_welcome_msg}
          isVisible={tooltipVisible}
          onClose={closeTooltip}
        >
          <img
            src={
              ai_avatar && isLinkOrText(ai_avatar)
                ? ai_avatar
                : studentDashboard_Small_AI_chatbot_image
            }
            alt={""}
            style={{
              width: "40px", // Adjust the width to your preference
              height: "40px", // Adjust the height to your preference
            }}
            onClick={() => {
              setOpenChatbot(!openChatbot);
              closeTooltip();
            }}
          />
        </CustomTooltip>
      </Fab>
      {openChatbot && !loading ? (
        <div className="studentDashboard-chatbot-wrapper">
          <div
            className={`studentDashboard-chatbot-container`}
            ref={chatbotRef} // Apply the ref here
          >
            <CustomChatbot
              chatHistory={chatHistory}
              socketUrl={newWebSocketURL}
              socketPayload={payload}
              ai_welcome_msg={ai_welcome_msg}
              ai_avatar={ai_avatar}
              ai_name={ai_name}
              school_name={"ProSchool"}
              roomId={roomId}
              onClose={() => setOpenChatbot(!openChatbot)}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default StudentLessonChatbot;
