import React, { useEffect, useRef } from "react";
import "./GoogleDriveUpload.css";
import UploadFromDevice from "./UploadFromDevice";
import { useAWSS3FileUpload } from "../AWSFileUpload/AWSFileUpload";

const FileUpload = ({
  showUploadPop,
  selectedFiles,
  setSelectedFiles,
  setuploadedFileLink,
  setShowUploadPop,
  fileInputRef,
}) => {
  const popupRef = useRef(null); // Reference for the popup container

  const { handleFileChange } = useAWSS3FileUpload({
    selectedFiles,
    setSelectedFiles,
    setuploadedFileLink,
    setShowUploadPop,
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Close popup if click is outside the popup container
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowUploadPop(false);
      }
    };

    // Attach event listener on mount
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowUploadPop]);

  return (
    <>
      {showUploadPop && (
        <div className="google-drive-popup-wrapper">
          <div className="google-drive-popup-container" ref={popupRef}>
            <UploadFromDevice
              setShowUploadPop={setShowUploadPop}
              handleFileChange={handleFileChange}
              fileInputRef={fileInputRef}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default FileUpload;
