import Fab from "@mui/material/Fab";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import * as React from "react";
const MutipleFloatingChatBot = (props) => {
  return (
    <>
      <Fab
        hidden={props?.getTeacherActiveChat?.length === 0}
        sx={{
          position: "absolute",
          bottom: 45,
          right: 45,
          color: "common.white",
          // set image to circle
          "& img": {
            borderRadius: "100%",
          },
        }}
        size="small"
        aria-label="add"
      >
        <img
          src={props?.teacherChat?.ai_avatar}
          alt={props?.teacherChat?.name}
          onClick={() => props?.onClickTeacher(props?.teacherChat)}
        />
      </Fab>
      {/* <SpeedDial
        ariaLabel="SpeedDial basic example"
        hidden={props?.getTeacherActiveChat?.length === 0}
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon />}
      >
        {props?.getTeacherActiveChat?.map((action, index) => (
          <SpeedDialAction
            key={index}
            onClick={() => props?.onClickTeacher(action)}
            icon={<img src={action?.ai_avatar} alt={action?.name} />}
            tooltipTitle={action?.name}
          />
        ))}
      </SpeedDial> */}
    </>
  );
};

export default MutipleFloatingChatBot;
